<template>
  <!-- 页面name：督导报表 -->
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">督导报表</div>
      <div>
        <el-popover placement="right" width="400" trigger="click">
          <div>分值取值规则：</div>
          <div>1）总分：该督导主题选择模板的总分数；</div>
          <div>2）园校评分：园校第一次提交的评分总数；</div>
          <div>3）子公司评分：子公司第一次提交的评分总数；</div>
          <div>
            4）总部评分：总部如果有评分则显示总部第一次评分分值，否则为空，取平均值时按0计算；
          </div>
          <div>
            5）督导后得分：最后一次评分，如果有总部评分，则取总部最后一次评分；如果总部没有评分，则取子公司最后一次评分；
          </div>
          <div>
            6）督导提升率是比较的是督导（整改）前后的上级评分，园校自评不作为参考
          </div>
          <div>
            公式：督导提升率=（督导后得分-分公司第一次评分）/任务模板总分*100%，四舍五入保留两位小数；
          </div>
          <div>
            7）达标率是对比督导（整改）前后，该任务下园校的达标率提升。标准是（得分/模板总分>=85%）；
          </div>
          <div>①达标率提升=督导后达标率-督导前达标率；</div>
          <div>
            ②督导后达标=【（督导后得分/模板总分）>=85%的园校个数】/督导任务下所有园校个数；
          </div>
          <div>
            ③督导前达标=【（分公司第一次评分/模板总分）>=85%的园校个数】/督导任务下所有园校个数；
          </div>
          <el-button
            type="primary"
            class="sheet-btn sheet-btn-left"
            slot="reference"
            >查看规则</el-button
          >
        </el-popover>
        <el-button
          type="primary"
          class="sheet-btn"
          v-if="isMerge"
          @click="mergeTask"
          >合并任务</el-button
        >
        <el-button
          type="primary"
          class="sheet-btn"
          v-if="isExport"
          @click="exportTast"
          >导出</el-button
        >
      </div>
    </div>
    <!-- 表单 -->
    <el-form
      :model="form"
      onsubmit="return false"
      :inline="true"
      class="search-form"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item>
            <el-select
              v-model="form.showLevel"
              class="select-input"
              clearable
              placeholder="展现层级"
              @change="handleShowLevelChange"
            >
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.showLevel != '3'">
            <el-select
              v-model="form.orgId"
              class="select-input"
              clearable
              placeholder="分公司"
              @change="handleCompanyChange"
            >
              <el-option
                v-for="item in companyOptions"
                :key="item.label"
                :label="item.name"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.showLevel == '1'">
            <el-select
              v-model="form.parkId"
              class="select-input"
              clearable
              placeholder="园校"
              :disabled="isHandleSchool"
            >
              <el-option
                v-for="item in schoolOptions"
                :key="item.label"
                :label="item.name"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="form.supervisionTopic"
              class="select-input"
              placeholder="督导主题"
            />
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="form.supervisionMethod"
              class="select-input"
              clearable
              placeholder="督导方式"
            >
              <el-option
                v-for="item in superviseTypeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="schoolYear"
              class="select-input"
              clearable
              placeholder="学年"
              @change="handleSchoolYearChange"
            >
              <el-option
                v-for="item in yearOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-select
              v-model="form.contrastLevel"
              class="select-input"
              clearable
              placeholder="对比项目"
            >
              <el-option
                v-for="item in compareLevelOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <div class="input-range">
              <el-input
                v-model="form.scoreBegin"
                class="range-input"
                placeholder="开始分数"
              />
              <div class="line"></div>
              <el-input
                v-model="form.scoreEnd"
                class="range-input"
                placeholder="结束分数"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <div class="input-range">
              <el-input
                v-model="form.ratioBegin"
                class="range-input rate"
                placeholder="开始分数/总分"
              />%
              <div class="line"></div>
              <el-input
                v-model="form.ratioEnd"
                class="range-input rate"
                placeholder="结束分数/总分"
              />%
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchFun"
              >查询</el-button
            >
            <el-button icon="el-icon-refresh-right" @click="resetFun"
              >重置</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.91667vw)'"
      :autoHeight="false"
    >
      <el-table-column
        prop="name"
        label="名称"
        align="center"
        width="180"
        slot="name"
      ></el-table-column>
      <el-table-column
        prop="supervisionTopic"
        label="督导主题"
        align="center"
        width="180"
        slot="supervisionTopic"
      ></el-table-column>
      <el-table-column
        prop="supervisionMethodName"
        label="督导方式"
        align="center"
        width="100"
        slot="supervisionMethodName"
      ></el-table-column>
      <el-table-column
        prop="totalScore"
        label="总分"
        align="center"
        slot="totalScore"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="parkScore"
        label="园校评分"
        width="120"
        align="center"
        slot="parkScore"
      ></el-table-column>
      <el-table-column
        prop="proportion"
        label="园校评分/总分"
        width="120"
        align="center"
        slot="proportion"
      >
	  <!-- {{ scope.row.proportion }}% -->
	  <template slot-scope="scope">
	    <span v-if="scope.row.proportion=='-'">-</span>
	    <span v-else> {{ scope.row.proportion + "%" }} </span>
	  </template>
	  </el-table-column>
      <el-table-column
        prop="branchScore"
        label="分公司评分"
        width="120"
        align="center"
        slot="branchScore"
      ></el-table-column>
      <el-table-column
        prop="branchProportion"
        label="分公司评分/总分"
        width="130"
        align="center"
        slot="branchProportion"
      >
	  <!-- {{ scope.row.branchProportion }}% -->
	  <template slot-scope="scope">
	    <span v-if="scope.row.branchProportion=='-'">-</span>
	    <span v-else> {{ scope.row.branchProportion + "%" }} </span>
	  </template>
	  </el-table-column>
      <el-table-column
        prop="groupScore"
        label="总部评分"
        width="120"
        align="center"
        slot="groupScore"
      ></el-table-column>
      <el-table-column
        prop="groupProportion"
        label="总部评分/总分"
        align="center"
        width="120"
        slot="groupProportion"
      >
	  <!-- {{ scope.row.groupProportion }}% -->
	  <template slot-scope="scope">
	    <span v-if="scope.row.groupProportion=='-'">-</span>
	    <span v-else> {{ scope.row.groupProportion + "%" }} </span>
	  </template>
	  </el-table-column>
      <el-table-column
        prop="superviseScore"
        label="督导后得分"
        width="120"
        align="center"
        slot="superviseScore"
      ></el-table-column>
      <el-table-column
        prop="liftRate"
        label="督导提升率"
        width="120"
        align="center"
        slot="liftRate"
      >
	  <!-- {{ scope.row.liftRate }}% -->
	  <template slot-scope="scope">
	    <span v-if="scope.row.liftRate=='-'">-</span>
	    <span v-else> {{ scope.row.liftRate + "%" }} </span>
	  </template>
	  </el-table-column>
      <el-table-column
        prop="complianceRate"
        label="达标率提升"
        width="120"
        align="center"
        slot="complianceRate"
      >
	  <!-- {{ scope.row.complianceRate }}% -->
	  <template slot-scope="scope">
	    <span v-if="scope.row.complianceRate=='-'">-</span>
	    <span v-else> {{ scope.row.complianceRate + "%" }} </span>
	  </template>
	  </el-table-column>
    </l-table>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
import downloadFile from "@/utils/downloadFile";
export default {
  name: "superviseReport",
  components: { LTable },
  data() {
    return {
      dialogVisible: false,
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "名称",
          slotName: "name",
        },
        {
          label: "督导主题",
          slotName: "supervisionTopic",
        },
        {
          label: "督导方式",
          slotName: "supervisionMethodName",
        },
        {
          label: "总分",
          slotName: "totalScore",
        },
        {
          label: "园校评分",
          slotName: "parkScore",
        },
        {
          label: "园校评分/总分",
          slotName: "proportion",
        },
        {
          label: "分公司评分",
          slotName: "branchScore",
        },
        {
          label: "分公司评分/总分",
          slotName: "branchProportion",
        },
        {
          label: "总部评分",
          slotName: "groupScore",
        },
        {
          label: "总部评分/总分",
          slotName: "groupProportion",
        },
        {
          label: "督导后得分",
          slotName: "superviseScore",
        },
        {
          label: "督导提升率%",
          slotName: "liftRate",
        },
        {
          label: "达标率提升%",
          slotName: "complianceRate",
        },
      ],
      title: "",
      form: {
        showLevel: "1",
        orgId: "",
        parkId: "",
        supervisionTopic: "",
        supervisionMethod: "",
        yearBegin: "",
        yearEnd: "",
        contrastLevel: "1",
        scoreBegin: "",
        scoreEnd: "",
        ratioBegin: "",
        ratioEnd: "",
      },
      schoolYear: "", //学年下拉
      isHandleSchool: true,
      publishTime: [], //发布时间选择的时间段
      levelOptions: [
        {
          name: "园校",
          value: "1",
        },
        {
          name: "分公司",
          value: "2",
        },
        {
          name: "总部",
          value: "3",
        },
      ], //展现层级下拉
      compareLevelOptions: [
        {
          name: "园校",
          value: "1",
        },
        {
          name: "分公司",
          value: "2",
        },
        {
          name: "总部",
          value: "3",
        },
      ], //对比项目下拉
      companyOptions: [], //分公司下拉
      schoolOptions: [], //园校下拉
      superviseTypeOptions: [
        {
          name: "远程督导",
          value: "1",
        },
        {
          name: "现场督导",
          value: "2",
        },
      ], //督导方式下拉
      yearOptions: [], //学年下拉
      tableData: [], //表单数据
      permissionButtonList: [], // 权限按钮list
      isMerge: false,
      isExport: false,
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isMerge = val.indexOf("superviseReport:merge") != -1;
          this.isExport = val.indexOf("superviseReport:export") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    this.getSchoolYearOption();
    this.getRangeList("NODE_ORG");
    // this.tableLoading = true;
    // this.init();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },
  activated(){
    this.tableLoading = true;
    this.init();
  },
  methods: {
    getPermissionButton,
    isHasPermission,

    /** 初始化 */
    init() {
      let param = {};
      for (let key in this.form) {
        if (this.form[key]) {
          param[key] = this.form[key];
        }
      }
      param.current = this.page.num;
      param.size = this.page.size;
      this.$api
        .getSuperviseReportList(param)
        .then((res) => {
          if (res.data.code == 0) {
            this.tableLoading = false;
            res.data.data.records.map((item) => {
              if (this.form.showLevel == "1") {
                item.name = item.parkName;
              } else if (this.form.showLevel == "2") {
                item.name = item.orgName;
              } else {
                item.name = "总部";
              }
              let supervisionMethodName = "";
              let supervisionMethodIndex = this.superviseTypeOptions.findIndex(
                (supervisionMethodInfo) => {
                  return supervisionMethodInfo.value == item.supervisionMethod;
                }
              );
              if (supervisionMethodIndex != -1) {
                supervisionMethodName =
                  this.superviseTypeOptions[supervisionMethodIndex].name;
              }
              item.supervisionMethodName = supervisionMethodName;
            });
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableLoading = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    //获取学年
    getSchoolYearOption() {
      let list = [];
      let lastYear = null;
      let year = null;
      let nextYear = null;
      let date = new Date();
      if (date.getMonth() < 8) {
        lastYear = date.getFullYear() - 2;
        year = date.getFullYear() - 1;
        nextYear = date.getFullYear();
      } else {
        lastYear = date.getFullYear() - 1;
        year = date.getFullYear();
        nextYear = date.getFullYear() + 1;
      }
      list = [
        {
          name: lastYear + "~" + year + "学年",
          value: "1",
          yearBegin: lastYear + "-09-01",
          yearEnd: year + "-08-31",
        },
        {
          name: year + "~" + nextYear + "学年",
          value: "2",
          yearBegin: year + "-09-01",
          yearEnd: nextYear + "-08-31",
        },
      ];
      this.yearOptions = list;
    },
    //处理选择学年变化
    handleSchoolYearChange(val) {
      let yearInfo = this.yearOptions.find((info) => {
        return info.value == val;
      });
      this.form.yearBegin = yearInfo.yearBegin;
      this.form.yearEnd = yearInfo.yearEnd;
    },
    //处理选择展现层级变化
    handleShowLevelChange(val) {
      console.log(val);
      this.form.parkId = "";
      this.isHandleSchool = true;
      this.schoolOptions = [];
      if (val == "3") {
        this.form.orgId = "";
      }
    },
    //处理选择分公司变化
    handleCompanyChange(val) {
      console.log(val);
      if (this.form.showLevel == "1") {
        this.form.parkId = "";
        this.schoolOptions = [];
        if (val) {
          this.isHandleSchool = false;
          this.getRangeList("NODE_SCHOOL", val);
        } else {
          this.isHandleSchool = true;
        }
      } else if (this.form.showLevel == "2") {
        this.form.parkId = "";
        this.isHandleSchool = true;
        this.schoolOptions = [];
      }
    },
    //获取分公司和园校下拉
    getRangeList(type, parent) {
      let param = {
        type,
      };
      if (type == "NODE_SCHOOL") {
        param.parent = parent;
      }
      this.$api
        .getCompanyDept(param)
        .then((res) => {
          if (res.data.code == 0) {
            if (type == "NODE_SCHOOL") {
              this.schoolOptions = res.data.data || [];
            } else if (type == "NODE_ORG") {
              this.companyOptions = res.data.data || [];
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.showLevel = "1";
      this.form.orgId = "";
      this.form.parkId = "";
      this.isHandleSchool = true;
      this.schoolOptions = [];
      this.form.supervisionTopic = "";
      this.form.supervisionMethod = "";
      this.form.yearBegin = "";
      this.form.yearEnd = "";
      this.schoolYear = "";
      this.form.contrastLevel = "1";
      this.form.scoreBegin = "";
      this.form.scoreEnd = "";
      this.form.ratioBegin = "";
      this.form.ratioEnd = "";
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
    /** 批量督办 */
    mergeTask() {
      this.$router.push({ path: "/superviseReportDetail" });
    },
    //导出
    exportTast() {
      let url = "/supervise/report/exportList";
      downloadFile({ url, data: this.form, type: "get" });
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.tableLoading = true;
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .form-header-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .header-title {
      color: #3d3e4f;
      font-size: 22px;
      font-weight: bold;
    }
    .sheet-btn {
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      width: auto;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  .sheet-btn-left {
    margin-right: 20px;
  }

  /deep/.el-table {
    // margin-top: 14px;
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  /deep/.el-switch__label.is-active {
    color: #010334;
  }
  /deep/.el-switch__label {
    color: #010334;
  }
  /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #003685 !important;
    border-color: #003685 !important;
  }
  .table-handle {
    margin-right: 10px;
    margin-left: 0;
  }
  .tableList {
    height: calc(100% - 175px);
  }
  .description-div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .select-input {
    width: 200px;
  }
  .select-time {
    width: 300px;
  }

  .mask-content {
    width: 96%;
    height: 98%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    // background: rgba(0, 0, 0, 0.5);

    .mask-content-text {
      width: 600px;
      min-height: 200px;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: 20px;
      border: 1px solid #ccc;

      .show-content {
        flex: 1;
      }
      .close-show {
        flex-shrink: 0;

        .font-size-big {
          font-size: 22px;
        }
      }
    }
  }
}
</style>